<template>
  <div class="full-card">
    <div class="padding-l20 display-f justify-end ">
      <div class="display-f" style="max-width: 40%">
        <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addTask']">新增任务</a-button>
        <div class="filter margin-l20" @click="filterShow = true"></div>
      </div>
    </div>
    <a-card class="" style="margin-top: 15px">
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
      >
        <template slot="taskName" slot-scope="text, record">
          <div @click="$util.checkUrl(authority.viewTaskDetail, `/task/taskDetail?id=${record.id}`)" class="display-f align-items-c text-underline">
            <img src="@/assets/image/common/star_fill.png" alt="" class="margin-r5" v-if="record.taskLevel === 1">
            <span>{{ text }}</span>
          </div>
        </template>
        <template slot="taskStatus" slot-scope="text">
          <span v-if="text === 0">未开始</span>
          <span v-if="text === 1">审批中</span>
          <span v-if="text === 2">进行中</span>
          <span v-if="text === 3">已完成</span>
          <span v-if="text === 4">已搁置</span>
          <span v-if="text === 5">已取消</span>
        </template>
        <template slot="taskProgressValue" slot-scope="text">
          <a-progress :percent="text * 100 || 0" />
        </template>
        <template slot="startDate" slot-scope="text, record">
          <div>{{ text }}</div>
        </template>
        <template slot="endDate" slot-scope="text, record">
          <div :class="color[record.taskProgress]">{{ text }}</div>
        </template>
        <template slot="actualDurationDays" slot-scope="text, record">
          <div>{{ record.durationDays }}</div>
          <div :class="color[record.milestoneProgress]">{{ text }}</div>
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="filterForm"
            class="drawer-form"
        >
          <a-form-model-item :wrapperCol="{ span: 24 }">
            <a-input-search placeholder="任务名称/任务负责人/关联项目名称" v-model="filterForm.queryParam.name" @search="onFilter" />
          </a-form-model-item>
          <a-row style="margin-top: 20px">
            <a-form-model-item label="任务状态">
              <a-select v-model="filterForm.queryParam.taskStatus" placeholder="请选择任务状态">
                <a-select-option :value="0">未开始</a-select-option>
                <a-select-option :value="1">审批中</a-select-option>
                <a-select-option :value="2">进行中</a-select-option>
                <a-select-option :value="3">已完成</a-select-option>
                <a-select-option :value="4">已搁置</a-select-option>
                <a-select-option :value="5">已取消</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="任务类型">
              <a-select v-model="filterForm.queryParam.taskCategoryId" placeholder="请选择任务类型">
                <a-select-option :value="item.id" v-for="(item) in taskTypeList" :key="item.id">
                  {{ item.categoryName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="任务等级">
              <a-select v-model="filterForm.queryParam.taskLevel" placeholder="请选择任务等级">
                <a-select-option :value="1">重要</a-select-option>
                <a-select-option :value="2">普通</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="任务负责人">
              <a-select v-model="filterForm.queryParam.taskLeaderId" placeholder="请选择任务负责人">
                <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{item.staffName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="任务进度">
              <a-checkbox-group
                  v-model="filterForm.queryParam.taskProgressList"
                  name="checkboxgroup"
                  :options="checkOptions"
              />
            </a-form-model-item>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-form-model-item label="计划开始日期">
              <a-range-picker @change="(date, dateStr) => rangeChange(date, dateStr, 'planStart')"/>
            </a-form-model-item>
            <a-form-model-item label="计划结束日期">
              <a-range-picker @change="(date, dateStr) => rangeChange(date, dateStr, 'planEnd')"/>
            </a-form-model-item>
            <a-form-model-item label="实际开始日期">
              <a-range-picker @change="(date, dateStr) => rangeChange(date, dateStr, 'start') "/>
            </a-form-model-item>
            <a-form-model-item label="实际结束日期">
              <a-range-picker @change="(date, dateStr) => rangeChange(date, dateStr, 'end') "/>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="search" @click="onFilter">筛选</a-button>
          <a-button style="margin-left: 10px;" icon="reload" @click="onReset">重置</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="新增任务"
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
        v-if="createShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="任务名称" prop="taskName">
              <a-input placeholder="任务名称" v-model="form.taskName" />
            </a-form-model-item>
            <a-form-model-item label="任务状态" prop="taskStatus">
              <a-select v-model="form.taskStatus" placeholder="请选择任务状态">
                <a-select-option :value="0">未开始</a-select-option>
                <a-select-option :value="1">审批中</a-select-option>
                <a-select-option :value="2">进行中</a-select-option>
                <a-select-option :value="3">已完成</a-select-option>
                <a-select-option :value="4">已搁置</a-select-option>
                <a-select-option :value="5">已取消</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="任务类型" prop="taskCategoryId">
              <a-select v-model="form.taskCategoryId" placeholder="请选择任务类型">
                <a-select-option :value="item.id" v-for="(item, index) in taskTypeList" :key="item.id">
                  {{ item.categoryName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="任务等级" prop="taskLevel">
              <a-select v-model="form.taskLevel" placeholder="请选择任务等级">
                <a-select-option :value="1">重要</a-select-option>
                <a-select-option :value="2">普通</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="任务负责人" prop="taskLeaderId">
              <a-select v-model="form.taskLeaderId" placeholder="请选择任务负责人">
                <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{item.staffName }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-form-model-item label="计划开始日期" prop="planStartDate">
              <a-date-picker @change="planStartChange" />
            </a-form-model-item>
            <a-form-model-item label="计划结束日期" prop="planEndDate">
              <a-date-picker @change="planEndChange" />
            </a-form-model-item>
            <a-form-model-item label="任务描述" prop="remark">
              <a-textarea placeholder="请输入任务描述" v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {
  getTaskList,
  getTaskTypeList,
  createTask,
  deleteTask,
  getProjectMember
} from '@/api/task/task'
import { mapState } from 'vuex'
export default {
  name: 'task',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      columns: [
        {
          title: '任务名称',
          dataIndex: 'taskName',
          scopedSlots: { customRender: 'taskName' }
        },
        {
          title: '状态',
          dataIndex: 'taskStatus',
          scopedSlots: { customRender: 'taskStatus' }
        },
        {
          title: '任务类型',
          dataIndex: 'taskCategory'
        },
        {
          title: '任务负责人',
          dataIndex: 'taskLeader'
        },
        {
          title: '任务进度',
          dataIndex: 'taskProgressValue',
          scopedSlots: { customRender: 'taskProgressValue' }
        },
        {
          title: '关联项目',
          dataIndex: 'projectName'
        },
        {
          title: '计划开始日期',
          dataIndex: 'planStartDate',
          sorter: true
        },
        {
          title: '实际开始日期',
          dataIndex: 'startDate',
          scopedSlots: { customRender: 'startDate' },
          sorter: true
        },
        {
          title: '计划结束日期',
          dataIndex: 'planEndDate',
          scopedSlots: { customRender: 'planEndDate' },
          sorter: true
        },
        {
          title: '实际结束日期',
          dataIndex: 'endDate',
          scopedSlots: { customRender: 'endDate' },
          sorter: true
        },
        {
          title: '持续时间（天）',
          dataIndex: 'actualDurationDays',
          scopedSlots: { customRender: 'actualDurationDays' }
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime',
          sorter: true
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      linkShow: false,
      filesShow: false,
      filterForm: {
        queryParam: {
          projectId: this.id
        },
        pageNum: 1,
        pageSize: 50
      },
      form: {
        projectId: this.id
      },
      page: 1,
      roleList: [],
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 50
      },
      staffShow: false,
      customerShow: false,
      rules: {
        taskName: [
          { required: true, message: '请输入任务名称', trigger: 'blur' }
        ],
        taskStatus: [
          { required: true, message: '请选择任务状态', trigger: 'change' }
        ],
        taskCategoryId: [
          { required: true, message: '请选择任务类型', trigger: 'change' }
        ],
        taskLevel: [
          { required: true, message: '请选择任务等级', trigger: 'change' }
        ]
      },
      memberList: [],
      checkOptions: [
        { label: '计划内', value: 1 },
        { label: '滞后', value: 2 },
        { label: '提前', value: 3 }
      ],
      color: {
        1: 'font-c3',
        2: 'red',
        3: 'green',
      },
      taskTypeList: [],
      sortList: ['', 'taskProgressValue', 'planStartDate', 'startDate', 'planEndDate', 'endDate', 'updateTime']
    }
  },
  mounted () {
    this.getList()
    this.getMember()
    this.getTaskType()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getTaskList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    getTaskType () {
      getTaskTypeList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.taskTypeList = res.data.list
      })
    },
    pageChange (pages, filters, sorter) {
      if (sorter) {
        this.filterForm.queryParam.sortField = this.sortList.findIndex(item => item ===sorter.field)
        this.filterForm.queryParam.sortOrder = (sorter.order === "ascend" ? 1 : 2)
      }
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) this.form = JSON.parse(JSON.stringify(item))
      this.createShow = true
    },
    closeCreate () {
      this.form = {
        projectId: this.id
      }
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteTask({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createTask(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      console.log(this.filterForm.taskProgressList)
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        queryParam: {
          projectId: this.id
        },
        pageNum: 1,
        pageSize: 50
      }
      this.getList()
    },
    getMember () {
      getProjectMember({
        projectId: this.form.projectId
      }).then(res => {
        this.memberList = res.data
      })
    },
    rangeChange (date, dateStr, type) {
      switch (type) {
        case 'planStart' :
          this.filterForm.queryParam.beginPlanStartDate = dateStr[0]
          this.filterForm.queryParam.endPlanStartDate = dateStr[1]
          break
        case 'planEnd' :
          this.filterForm.queryParam.beginPlanEndDate = dateStr[0]
          this.filterForm.queryParam.endPlanEndDate = dateStr[1]
          break
        case 'start' :
          this.filterForm.queryParam.startStartDate = dateStr[0]
          this.filterForm.queryParam.endStartDate = dateStr[1]
          break
        case 'end' :
          this.filterForm.queryParam.beginEndDate = dateStr[0]
          this.filterForm.queryParam.endEndDate = dateStr[1]
          break
      }
    },
    planStartChange (date, dateString) {
      this.form.planStartDate = dateString
    },
    planEndChange (date, dateString) {
      this.form.planEndDate = dateString
    },
    projectChange () {
      this.getMember()
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
