import request from '@/utils/request'

export function getMilestoneList(data) {
    return request({
        url: `/milestone/page?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        method: 'POST',
        data: data.queryParam,
    })
}

export function getMilestonePrincipal() {
    return request({
        url: `/milestone/getMilestoneLeaders`,
        method: 'GET'
    })
}

export function createMilestone (data) {
    return request({
        url: data.id ? `/milestone/update/${data.id}` : `/milestone/create`,
        method: 'POST',
        data
    })
}

export function deleteMilestone(data) {
    return request({
        url: '/milestone/delete',
        method: 'POST',
        data,
    })
}









