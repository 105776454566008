<template>
  <div class="padding-t50">
    <a-row :gutter="10">
      <a-col span="5">
        <a-card title="基本信息" :bodyStyle="{'height': '500px'}">
          <div slot="extra">
            <a-button size="small" type="primary" ghost @click="infoSubmit" v-if="canModify">保存</a-button>
          </div>
          <div>
            <a-form-model
                ref="infoForm"
                labelAlign="right"
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 16 }"
                :model="detail"
                :rules="infoRules"
                :hideRequiredMark="true"
            >
              <a-row>
                <a-form-model-item label="项目名称" prop="productName">
                  <a-textarea placeholder="请输入项目名称" v-model="detail.projectName" :disabled="!canModify" />
                </a-form-model-item>
              </a-row>
              <a-row>
                <a-form-model-item  label="项目状态" prop="projectStatus">
                  <a-select v-model="detail.projectStatus" placeholder="请选择项目状态" :disabled="!canModify">
                    <a-select-option :value="1">洽谈中</a-select-option>
                    <a-select-option :value="2">规划中</a-select-option>
                    <a-select-option :value="3">实施中</a-select-option>
                    <a-select-option :value="4">验收中</a-select-option>
                    <a-select-option :value="5">已完成</a-select-option>
                    <a-select-option :value="6">已取消</a-select-option>
                    <a-select-option :value="7">已搁置</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="项目等级" prop="projectLevel">
                  <a-select v-model="detail.projectLevel" placeholder="请选择项目等级" :disabled="!canModify">
                    <a-select-option :value="1">一星</a-select-option>
                    <a-select-option :value="2">二星</a-select-option>
                    <a-select-option :value="3">三星</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="项目地区" prop="privince">
                  <District :level="2" @change="getDistrict" v-model="detail.privince" :disabled="!canModify"></District>
                </a-form-model-item>
                <a-form-model-item label="项目经纬度" prop="longitude">
                  <a-row :gutter="5">
                    <a-col span="12">
                      <a-input placeholder="请输入项目经度" v-model="detail.longitude"/>
                    </a-col>
                    <a-col span="12">
                      <a-input placeholder="请输入项目纬度" v-model="detail.latitude"/>
                    </a-col>
                  </a-row>
                </a-form-model-item>
                <a-form-model-item label="详细地址" prop="address">
                  <a-textarea placeholder="请输入详细地址" v-model="detail.address" :auto-size="{ minRows: 3, maxRows: 5 }"  :disabled="!canModify" />
                </a-form-model-item>
              </a-row>
              <a-row style="margin-top: 10px">
                <a-form-model-item label="启动日期">
                  <a-date-picker
                      :value="detail.startDate ? moment(detail.startDate) : null"
                      @change="startChange"
                      style="width: 100%"
                      :disabled="!canModify"
                  />
                </a-form-model-item>
                <a-form-model-item label="计划结束日期">
                  <a-date-picker
                      :value="detail.planEndDate ? moment(detail.planEndDate) : null"
                      @change="endChange"
                      style="width: 100%"
                      :disabled="!canModify"
                  />
                </a-form-model-item>
                <a-form-model-item label="实际结束日期">
                  <a-date-picker
                      :value="detail.actualEndDate ? moment(detail.actualEndDate) : null"
                      @change="actualEndChange"
                      style="width: 100%"
                      :disabled="!canModify"
                  />
                </a-form-model-item>
              </a-row>
            </a-form-model>
          </div>
        </a-card>
      </a-col>
      <a-col span="5">
        <a-card title="项目成员"  :bodyStyle="{'height': '500px'}">
<!--          <div slot="extra">-->
<!--            <a-button size="small" type="primary" ghost @click="infoSubmit" v-if="canModify">保存</a-button>-->
<!--          </div>-->
          <a-row style="height: calc(100% - 200px); overflow-y: auto">
            <a-row class="padding-tb10" v-for="(item, index) in memberList" :key="item.id">
              <a-col :span="10" class="font-c9">{{ item.positionName }}：</a-col>
              <a-col :span="10">{{ item.staffName }}</a-col>
              <a-col :span="4">
                <a-popconfirm title="确定删除?" @confirm="() => delMember(item)">
                <img src="@/assets/image/common/del.png" alt="" class="cursor" v-if="canModify">
                </a-popconfirm>
              </a-col>
            </a-row>
          </a-row>
          <a-row class="margin-t20" v-if="canModify">
            <a-form-model
                ref="memberForm"
                labelAlign="right"
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 16 }"
                :model="members"
                :rules="memberRules"
                :hideRequiredMark="true"
            >
              <a-form-model-item label="项目职位" prop="positionId">
                <a-select v-model="members.positionId" placeholder="请选择项目职位">
                  <a-select-option :value="item.id" v-for="(item) in jobList" :key="item.id">{{ item.positionName }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="员工" prop="staffName">
                <div @click="openStaff(1)">
                  <div class="border border-r4 line-h32 padding-lr10 cursor">
                    <span v-if="members.staffName">{{ members.staffName }}</span>
                    <span class="font-c8" v-else>请选择员工</span>
                  </div>
<!--                  <a-input placeholder="请选择员工" v-model="members.staffName" ></a-input>-->
                </div>
              </a-form-model-item>
              <a-row style="margin-top: 20px">
                <a-form-model-item :wrapper-col="{ span: 20, offset: 2 }">
                  <a-button type="primary" block @click="addMember">确认添加</a-button>
                </a-form-model-item>
              </a-row>
            </a-form-model>
          </a-row>
        </a-card>
      </a-col>
      <a-col span="5">
        <a-card title="客户及干系人" :bodyStyle="{'height': '500px'}">
          <a-form-model
              ref="stakeholderForm"
              labelAlign="right"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
              :model="stakeholder"
              :rules="stakeholderRules"
              :hideRequiredMark="true"
              style="height: 100%"
          >
            <a-form-model-item label="客户名称" prop="customerId">
              <div @click="openStaff(2)">
                <div class="border border-r4 line-h32 padding-lr10 cursor">
                  <span v-if="stakeholder.customerName">{{ stakeholder.customerName }}</span>
                  <span class="font-c8" v-else>请选择客户</span>
                </div>
<!--                <a-input placeholder="请选择客户" v-model="stakeholder.customerName" disabled></a-input>-->
              </div>
            </a-form-model-item>
            <a-form-model-item label="客户级别" prop="customerLevel">
              <span v-if="stakeholder.customerLevel === 1">重要</span>
              <span v-if="stakeholder.customerLevel === 2">普通</span>
              <span v-if="stakeholder.customerLevel === 3">非优</span>
            </a-form-model-item>
            <a-row style="height: calc(100% - 300px); overflow-y: auto">
              <a-row class="padding-tb10" v-for="(item) in projectStakeholderList" :key="item.id">
                <a-col :span="8" class="font-c9">
                  <template v-if="item.stakeholderLevel === 1">主要干系人</template>
                  <template v-if="item.stakeholderLevel === 2">干系人</template>
                  ：
                </a-col>
                <a-col :span="12">{{ item.stakeholderName }} <span class="margin-l10">{{ item.phone }}</span></a-col>
                <a-col :span="4" class="text-r">
                  <img src="@/assets/image/common/del.png" alt="" class="cursor" @click="delStakeholder(item)" v-if="canModify">
                </a-col>
              </a-row>
            </a-row>
            <a-row class="margin-t20" v-if="canModify">
              <a-form-model-item label="重要程度" prop="stakeholderLevel">
                <a-select v-model="stakeholder.stakeholderLevel" placeholder="请选择重要程度">
                  <a-select-option :value="1">主要干系人</a-select-option>
                  <a-select-option :value="2">干系人</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="客户干系人" prop="stakeholderId">
                <div @click="openStaff(3)">
                  <div class="border border-r4 line-h32 padding-lr10 cursor">
                    <span v-if="stakeholder.stakeholderName">{{ stakeholder.stakeholderName }}</span>
                    <span class="font-c8" v-else>请选择客户干系人</span>
                  </div>
<!--                  <a-input placeholder="请选择客户干系人" v-model="stakeholder.stakeholderName" disabled></a-input>-->
                </div>
              </a-form-model-item>
              <a-row style="margin-top: 20px">
                <a-form-model-item :wrapper-col="{ span: 20, offset: 2 }">
                  <a-button type="primary" block @click="addStakeholder">确认添加</a-button>
                </a-form-model-item>
              </a-row>
            </a-row>
          </a-form-model>
        </a-card>
      </a-col>
      <a-col span="9">
        <a-card title="监控视频" :bodyStyle="{'height': '500px'}">
          监控视频
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="10" class="margin-t15">
      <a-col span="5">
        <a-card title="数据指标" :bodyStyle="{'height': '800px'}">
          <div>
            <a-form-model
                labelAlign="right"
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 16 }"
            >
              <a-row v-if="detail.projectInfoDataDTO">
                <a-form-model-item label="项目进度">
                  <a-progress :percent="detail.projectInfoDataDTO.progressValue || 0" />
                </a-form-model-item>
                <a-form-model-item label="里程碑进度">
                  <a-progress
                      :percent="detail.projectInfoDataDTO.finishedMilestoneNum / detail.projectInfoDataDTO.totalMilestoneNum * 100"
                      :format="() => `${(detail.projectInfoDataDTO.finishedMilestoneNum || 0) + '/' + (detail.projectInfoDataDTO.totalMilestoneNum || 0)}`"
                  />
                </a-form-model-item>
                <a-form-model-item label="任务进度">
                  <a-progress
                      :percent="detail.projectInfoDataDTO.finishedTaskNum / detail.projectInfoDataDTO.totalTaskNum * 100"
                      :format="() => `${(detail.projectInfoDataDTO.finishedTaskNum || 0) + '/' + (detail.projectInfoDataDTO.totalTaskNum || 0)}`"

                  />
                </a-form-model-item>
                <a-form-model-item label="处理问题">
                  <a-progress
                      :percent="detail.projectInfoDataDTO.finishedProblemNum / detail.projectInfoDataDTO.totalProblemNum * 100"
                      :format="() => `${(detail.projectInfoDataDTO.finishedProblemNum || 0) + '/' + (detail.projectInfoDataDTO.totalProblemNum || 0)}`"
                  />
                </a-form-model-item>
                <a-form-model-item label="预算支出">
                  <a-progress :percent="detail.projectInfoDataDTO.planExpenditure || 0" />
                </a-form-model-item>
                <a-form-model-item :label="item.expenditureCategoryName" v-for="(item, index) in detail.projectInfoDataDTO.projectExpenditureDatas" :key="index">
                  <a-progress :percent="item.expenditurePercentage || 0" />
                </a-form-model-item>
<!--                <a-form-model-item :label="item.expenditureCategoryName" v-for="(item, index) in detail.projectInfoDataDTO.actualExpenditureDatas" :key="index">-->
<!--                  <a-progress :percent="item.expenditurePercentage * 100 || 0" />-->
<!--                </a-form-model-item>-->
              </a-row>
              <a-row class="margin-t20">
                <div class="">实际支出占比</div>
                <div class="height-200" id="pieChart"></div>
              </a-row>
            </a-form-model>
          </div>
        </a-card>
      </a-col>
      <a-col span="19">
        <EditTable
            :type="1"
            :projectId="id"
            :value="detail.planExpenditureDTO"
            :canModify="canModify"
            v-if="detail.planExpenditureDTO"
            @change="getDetail"
        >
        </EditTable>
        <EditTable
            :type="2"
            :projectId="id"
            :value="detail.actualExpenditureDTO"
            :canModify="canModify"
            v-if="detail.actualExpenditureDTO"
            style="margin-top: 15px"
            @change="getDetail"
        >
        </EditTable>

      </a-col>
    </a-row>
    <a-row :gutter="10" class="margin-t15">
      <a-col span="5">
        <a-card title="备注">
          <div slot="extra">
            <a-button size="small" type="primary" ghost @click="remarkSubmit" v-if="canModify">保存</a-button>
          </div>
          <a-textarea placeholder="请输入备注" v-model="detail.remark" :disabled="!canModify" style="height: 200px" />
        </a-card>
      </a-col>
      <a-col span="19">
        <a-card title="项目日志">
          <a-table
              :columns="logColumns"
              :data-source="detail.projectLogs"
              rowKey="id"
              bordered
          ></a-table>
        </a-card>
      </a-col>
    </a-row>
    <SelectStaff
        :visible="staffShow"
        :type="staffType"
        :value="stakeholder.customerId"
        @submit="staffChange"
        @close="staffShow = false"
        v-if="staffShow"
    ></SelectStaff>
  </div>
</template>

<script>
import District from '@/components/district/district'
import SelectStaff from '@/components/select/selectStaff'
import EditTable from './editTable'
import { createProject, getProjectDetail } from '../../../api/project/project'
import moment from 'moment';
import {
  createMember, createProjectStakeholder,
  deleteMember, deleteStakeholder,
  getMemberList,
  getProjectStakeholderList, saveProjectRemark
} from '../../../api/project/projectDetail'
import { mapState } from 'vuex'
import { getPositionList } from '../../../api/system/settings'
export default {
  name: 'overview',
  components: {
    District,
    SelectStaff,
    EditTable
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      detail: {
        projectInfoDataDTO: {},
      },
      canModify: true,
      infoRules: {
        projectName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ],
        projectStatus: [
          { required: true, message: '请选择项目状态', trigger: 'change' }
        ],
        projectLevel: [
          { required: true, message: '请选择项目等级', trigger: 'change' }
        ],
        privince: [
          { required: true, message: '请选择项目地区', trigger: 'change' }
        ],
        longitude: [
          { required: true, message: '请输入经纬度', trigger: 'blur' }
        ]
      },
      memberRules: {
        positionId: [
          { required: true, message: '请选择项目职位', trigger: 'change' }
        ],
        staffName: [
          { required: true, message: '请选择员工', trigger: 'change' }
        ]
      },
      stakeholderRules: {
        customerId: [
          { required: true, message: '请选择客户', trigger: 'change' }
        ],
        stakeholderLevel: [
          { required: true, message: '请选择重要程度', trigger: 'change' }
        ],
        stakeholderId: [
          { required: true, message: '请选择干系人', trigger: 'change' }
        ]
      },
      members: {},
      stakeholder: {},
      staffShow: false,
      jobList: [],
      memberList: [],
      projectStakeholderList: [],
      staffType: 1,
      logColumns: [
        {
          title: '时间',
          dataIndex: 'createTime'
        },
        {
          title: '事件内容',
          dataIndex: 'logContent'
        },
        {
          title: '触发人',
          dataIndex: 'creator'
        }
      ],
    }
  },
  mounted () {
    this.members.projectId = this.id
    this.stakeholder.projectId = this.id
    this.getDetail()
    this.getJob()
    this.getMember()
    this.getProjectStakeholder()
    this.canModify = this.authority.editProject
  },
  methods: {
    moment,
    getDetail () {
      getProjectDetail({
        id: this.id
      }).then(res => {
        this.detail = res.data
        this.detail.privince = [res.data.province, res.data.city]
        if (!res.data.planExpenditureDTO) this.detail.planExpenditureDTO = {}
        if (!res.data.actualExpenditureDTO) this.detail.actualExpenditureDTO = {}
        this.drawChart()
      })
    },
    getDistrict (data) {
      this.detail.province = data[0]
      this.detail.city = data[1]
    },
    startChange (date, dateStr) {
      this.detail.startDate = dateStr
    },
    endChange (date, dateStr) {
      this.detail.planEndDate = dateStr
    },
    actualEndChange (date, dateStr) {
      this.detail.actualEndDate = dateStr
    },
    infoSubmit () {
      this.$refs.infoForm.validate(valid => {
        if (valid) {
          createProject(this.detail).then(() => {
            this.$message.success('操作成功！')
            this.getDetail()
          })
        }
      })
    },
    staffChange (data) {
      switch (this.staffType) {
        case 1:
          this.members.staffId = data.id
          this.members.staffName = data.staffName
          this.$refs.memberForm.clearValidate('staffName', (res) => {console.log(res)})
          break
        case 2:
          this.stakeholder.customerId = data.id
          this.stakeholder.customerName = data.customerName
          this.stakeholder.customerLevel = data.customerLevel
          console.log(this.stakeholder.customerId)
          this.$refs.stakeholderForm.clearValidate('customerId', (res) => {console.log(res)})
          break
        case 3:
          this.stakeholder.stakeholderName = data.stakeholderName
          this.stakeholder.stakeholderId = data.id
          this.$refs.stakeholderForm.clearValidate('stakeholderId', (res) => {console.log(res)})
          break
      }
      this.staffShow = false
    },
    getJob () {
      getPositionList().then(res => {
        this.jobList = res.data.list
      })
    },
    openStaff (type) {
      if (type === 3 && !this.stakeholder.customerId) {
        this.$message.error('请先选择客户！')
        return
      }
      if (this.canModify) {
        this.staffType = type
        this.staffShow = true
      }
    },
    addMember () {
      this.$refs.memberForm.validate(valid => {
        if (valid) {
          createMember(this.members).then(() => {
            this.$message.success('添加成功！')
            this.getMember()
          })
        }
      })
    },
    getMember () {
      getMemberList({
        projectId: this.id
      }).then(res => {
        this.memberList = res.data
      })
    },
    delMember (item) {
      deleteMember({
        id: item.id
      }).then(() => {
        this.$message.success('删除成功！')
        this.getMember()
      })
    },
    delStakeholder (item) {
      deleteStakeholder({
        id: item.id
      }).then(() => {
        this.$message.success('删除成功！')
        this.getProjectStakeholder()
      })
    },
    getProjectStakeholder() {
      getProjectStakeholderList({
        projectId: this.id
      }).then(res => {
        this.projectStakeholderList = res.data
      })
    },
    addStakeholder () {
      this.$refs.stakeholderForm.validate(valid => {
        if (valid) {
          createProjectStakeholder(this.stakeholder).then(() => {
            this.$message.success('添加成功！')
            this.getProjectStakeholder()
          })
        }
      })
    },
    remarkSubmit () {
      saveProjectRemark({
        projectId: this.id,
        remark: this.detail.remark
      }).then(() => {
        this.$message.success('保存成功！')
      })
    },
    drawChart () {
      let arr = []
      if (!this.detail.projectInfoDataDTO.actualExpenditureDatas) return
      this.detail.projectInfoDataDTO.actualExpenditureDatas.forEach(item => {
        arr.push({
          value: item.expenditurePercentage,
          name: item.expenditureCategoryName
        })
      })
      const pieChart = this.$echarts.init(document.getElementById('pieChart'))
      const optionPie = {
        tooltip: {
          trigger: 'item',
          formatter: "{b}{d}%",
          confine: true
        },
        series: [
          {
            name: '实际支出占比',
            type: 'pie',
            data: arr,
            labelLine: {
              show: true
            },
            label: {
              show: true
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      pieChart.setOption(optionPie)
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
