<template>
  <div class="detail-page">
    <div class="position-f display-f" style="top: 125px; max-width: 60%">
      <a-button class="width-120" :type="index === 1 ? 'primary' : ''" @click="switchTab(1)">概览</a-button>
      <a-button class="margin-l15 width-120" :type="index === 2 ? 'primary' : ''" @click="switchTab(2)">任务</a-button>
      <a-button class="margin-l15 width-120" :type="index === 3 ? 'primary' : ''" @click="switchTab(3)">甘特图</a-button>
      <a-button class="margin-l15 width-120" :type="index === 4 ? 'primary' : ''" @click="switchTab(4)">里程碑</a-button>
      <a-button class="margin-l15 width-120" :type="index === 5 ? 'primary' : ''" @click="switchTab(5)">文件库</a-button>
      <a-button class="margin-l15 width-120" :type="index === 6 ? 'primary' : ''" @click="switchTab(6)">问题</a-button>
      <a-button class="margin-l15 width-120" :type="index === 7 ? 'primary' : ''" @click="switchTab(7)">售后提醒</a-button>
      <a-button class="margin-l40 width-120" type="danger" icon="delete" @click="delProject" v-hasPermission="['deleteProject']">删除</a-button>
    </div>
    <Overview :id="$route.query.id" v-if="index === 1"></Overview>
    <Task :id="$route.query.id" v-if="index === 2"></Task>
    <GanttChart :id="$route.query.id" v-if="index === 3"></GanttChart>
    <Milestone :id="$route.query.id" v-if="index === 4"></Milestone>
    <FileLibrary :id="$route.query.id" v-if="index === 5"></FileLibrary>
    <Problem :id="$route.query.id" ref="problemDom" v-if="index === 6"></Problem>
    <afterSale :id="$route.query.id" v-if="index === 7"></afterSale>
  </div>
</template>

<script>

import Overview from './overview'
import Task from './task'
import GanttChart from './gantt'
import Milestone from './milestone'
import FileLibrary from './fileLibrary'
import Problem from './problem'
import afterSale from './afterSale'
import { deleteProject } from '../../../api/project/project'
import { mapState } from 'vuex'
export default {
  name: 'projectDetail',
  components: {
    Overview,
    Task,
    GanttChart,
    Milestone,
    FileLibrary,
    Problem,
    afterSale
  },
  data () {
    return {
      index: 1
    }
  },
  mounted () {
    this.$store.commit('SET_TITLE', this.$route.query.title)
  },
  destroyed () {
    this.$store.commit('SET_TITLE', '')
  },
  methods: {
    delProject () {
      this.$confirm({
        title: '提示',
        content: '确认删除该数据？',
        okText: '确认',
        cancelText: '取消',
        centered: true,
        onOk: ()=> {
          deleteProject({
            ids: [this.$route.query.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.$router.go(-1)
          })
        }
      });
    },
    switchTab (index) {
      this.index = index
      if (index === 6 && this.$refs.problemDom) {
        this.$refs.problemDom.pageType = 'list'
      }
      this.$forceUpdate()
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      rights: state => state.user.permission,
    }),
  },
}
</script>

<style scoped>

</style>
