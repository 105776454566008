<template>
  <div class="full-card padding-t50">
    <Gantt type="project" :id="id" v-if="id"></Gantt>
  </div>
</template>

<script>
import Gantt from '@/components/gantt/gantt.vue'
export default {
  name: 'projectGantt',
  components: {
    Gantt
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
  },
  computed: {

  },
}
</script>

<style lang="scss">
  .gantt-container {
    height: 600px;
  }
  .gantt-task-radius {
    border-radius: 10px !important;
  }
  .gantt_split_parent {
    opacity: 1;
  }
</style>
