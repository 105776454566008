<template>
  <div class="full-card">
    <div class="display-f justify-end">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addSharedFileLibrary']">新增文件</a-button>
      <div class="filter margin-l20" @click="filterShow = true"></div>
    </div>
    <a-card style="margin-top: 15px">
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
      >
        <template slot="fileType" slot-scope="text">
          <span v-if="text === 1">上传</span>
          <span v-if="text === 2">共享</span>
          <span v-if="text === 3">云文件</span>
        </template>
        <template slot="fileStage" slot-scope="text">
          <span v-if="text === 1">项目前期准备</span>
          <span v-if="text === 2">项目中期</span>
          <span v-if="text === 3">项目后期</span>
        </template>
        <template slot="id" slot-scope="text, record">
          <img src="@/assets/image/common/download.png" alt="" @click="openFiles(record)" v-show="record.fileUrl"  v-has-permission="['downloadSharedFileLibrary']">
          <img src="@/assets/image/common/edit.png" alt="" class="margin-l20" @click="openCreate('edit', record)" v-has-permission="['editSharedFileLibrary']">
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deleteSharedFileLibrary']">
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="500px"
        class="drawer"
    >
      <a-form-model labelAlign="right" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :model="filterForm">
        <a-form-model-item :wrapperCol="{ span: 24 }">
          <a-input-search placeholder="文件名称" v-model="filterForm.fileName" @search="onFilter" />
        </a-form-model-item>
        <a-row class="margin-t20">
          <a-form-model-item  label="文件类型">
            <a-select v-model="filterForm.fileType" placeholder="请选择文件类型">
              <a-select-option :value="1">上传</a-select-option>
              <a-select-option :value="2">引用共享</a-select-option>
              <a-select-option :value="3">云文件</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item  label="关联任务">
            <a-select v-model="filterForm.taskId" placeholder="请选择关联任务">
              <a-select-option :value="item.id" v-for="(item) in taskList" :key="item.id">{{ item.taskName }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="项目阶段" prop="fileStage">
            <a-select v-model="filterForm.fileStage" placeholder="请选择项目阶段">
              <a-select-option :value="1">项目前期准备</a-select-option>
              <a-select-option :value="2">项目中期</a-select-option>
              <a-select-option :value="3">项目后期</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-form-model-item :wrapper-col="{ span: 18, offset: 6 }">
            <a-button type="primary" @click="onFilter">筛选</a-button>
            <a-button style="margin-left: 10px;" @click="onReset">重置</a-button>
          </a-form-model-item>
        </a-row>
      </a-form-model>
    </a-drawer>
    <a-drawer
        :title="`${form.id ? '编辑' : '新增'}文件`"
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="900px"
        class="drawer"
        v-if="createShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="文件名称" prop="fileName">
              <a-input placeholder="请输入文件名称" v-model="form.fileName" />
            </a-form-model-item>
            <a-form-model-item label="文件类型" prop="fileType">
              <a-radio-group name="radioGroup" :default-value="1" v-model="form.fileType">
                <a-radio :value="1">上传</a-radio>
                <a-radio :value="2">共享</a-radio>
                <a-radio :value="3">云文件</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="项目阶段" prop="fileStage">
              <a-select v-model="form.fileStage" placeholder="请选择项目阶段">
                <a-select-option :value="1">项目前期准备</a-select-option>
                <a-select-option :value="2">项目中期</a-select-option>
                <a-select-option :value="3">项目后期</a-select-option>
              </a-select>
            </a-form-model-item>
            <template v-if="form.fileType === 1">
              <a-form-model-item label="上传文件" prop="fileUrl" :required="true">
                <e-upload v-model="form.fileUrl"></e-upload>
              </a-form-model-item>
            </template>
            <template v-if="form.fileType === 2">
              <a-form-model-item label="引用共享文件" prop="sharedFileId">
                <a-select v-model="form.sharedFileId" placeholder="请引用共享文件">
                  <a-select-option :value="item.id" v-for="(item, index) in shareFileList" :key="item.id">{{ item.fileName }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </template>
            <a-form-model-item label="关联任务" prop="projectTaskIds">
              <a-select mode="multiple" v-model="form.projectTaskIds" placeholder="请选择关联任务" @change="taskChange">
                <a-select-option :value="item.id" v-for="(item, index) in taskList" :key="item.id">{{ item.taskName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="关联任务成员" prop="taskMemberIds">
              <a-select mode="multiple" v-model="form.taskMemberIds" placeholder="请选择关联任务成员">
                <a-select-option :value="item.staffId" v-for="(item, index) in taskMember" :key="item.id">{{ item.staffName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="关联产品" prop="productId">
              <a-select v-model="form.productId" placeholder="请选择关联产品">
                <a-select-option :value="item.id" v-for="(item, index) in productList" :key="item.id">{{ item.productName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="浏览下载权限" prop="projectPositionIds">
              <a-select mode="multiple" v-model="form.projectPositionIds" placeholder="请选择浏览下载权限">
                <a-select-option :value="item.id" v-for="(item, index) in jobList" :key="item.id">{{ item.postName }}（{{ item.deptName}}）</a-select-option>
              </a-select>
            </a-form-model-item>
            <template v-if="form.fileType === 3">
              <a-form-model-item label="云文件内容" prop="fileContent">
                <QuillEditor v-model="form.fileContent"></QuillEditor>
              </a-form-model-item>
            </template>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
            <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
            <a-button icon="close" class="margin-l15" @click="closeCreate">取消</a-button>
        </div>
      </div>

    </a-drawer>
  </div>
</template>

<script>
import { getProjectFileList, deleteProjectFile, createProjectFile } from '@/api/project/projectDetail'
import { getProductList } from '@/api/product/product'
import storage from 'store'
import { getJobList } from '@/api/humanResources/job'
import { getTaskList, getTaskMember } from '@/api/task/task'
import { getFileList } from '@/api/project/file'
import QuillEditor from '@/components/Editor/QuillEditor'
export default {
  name: 'sharedFileLibrary',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  components: {
    QuillEditor
  },
  data () {
    return {
      columns: [
        {
          title: '文件名称',
          dataIndex: 'fileName'
        },
        {
          title: '文件类型',
          dataIndex: 'fileType',
          scopedSlots: { customRender: 'fileType' }
        },
        {
          title: '项目阶段',
          dataIndex: 'fileStage',
          scopedSlots: { customRender: 'fileStage' }
        },
        {
          title: '浏览下载权限',
          dataIndex: 'fileAuth'
        },
        {
          title: '关联任务',
          dataIndex: 'projectTask'
        },
        {
          title: '关联任务成员',
          dataIndex: 'taskMember'
        },
        {
          title: '关联产品',
          dataIndex: 'productName'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      filterForm: {
        projectId: this.id,
        pageNum: 1,
        pageSize: 10
      },
      form: {
        fileType: 1,
        projectId: this.id
      },
      page: 1,
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      rules: {
        fileName: [
          { required: true, message: '请输入文件名称', trigger: 'blur' }
        ],
        fileType: [
          { required: true, message: '请选择文件类型', trigger: 'change' }
        ],
        fileUrl: [
          { required: true, message: '请上传文件', trigger: 'change' }
        ],
        sharedFileId: [
          { required: true, message: '请选择引用共享文件', trigger: 'change' }
        ]
      },
      productList: [],
      jobList: [],
      headers: {
        'token': storage.get('Access-Token')
      },
      fileList: [],
      taskList: [],
      shareFileList: [],
      taskMember: [],
    }
  },
  mounted () {
    this.getList()
    this.getProduct()
    this.getJob()
    this.getTask()
    this.getShareFileList()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getProjectFileList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) {
        if (!item.projectPositionIds) item.projectPositionIds = []
        if (!item.taskMemberIds) item.taskMemberIds = []
        this.form = { ...item }
        this.fileList = [{
          uid: item.id,
          name: item.fileName,
          status: item.done,
          url: 'http://project.xteknology.com/upload/' + item.fileUrl
        }]
      } else {
        this.fileList = []
      }
      if (item && item.projectTaskIds && item.projectTaskIds.length) {
        getTaskMember({
          ids: item.projectTaskIds
        }).then(res => {
          this.taskMember = res.data
          this.createShow = true
        })
      } else {
        this.form.projectTaskIds = []
        this.createShow = true
      }

    },
    closeCreate () {
      this.form = {
        fileType: 1,
        projectId: this.id
      }
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteProjectFile({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      console.log(this.form)
      let item = {...this.form}
      if (!(item.projectPositionIds && item.projectPositionIds.length)) item.projectPositionIds = null
      if (!(item.taskMemberIds && item.taskMemberIds.length)) item.taskMemberIds = null
      this.$refs.form.validate(valid => {
        if (valid) {
          createProjectFile(item).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        projectId: this.id,
        pageNum: 1,
        pageSize: 10
      }
      this.getList()
    },
    openFiles (item) {
      window.open('http://project.xteknology.com/upload/' + item.fileUrl)
    },
    getProduct () {
      getProductList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.productList = res.data.list
      })
    },
    fileChange (res) {
      if (res.file.response) {
        this.form.fileUrl = res.file.response.data
      } else {
        this.form.fileUrl = ''
      }
      this.fileList = res.fileList
    },
    getJob () {
      getJobList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.jobList = res.data.list
      })
    },
    getTask () {
      getTaskList({
        queryParam: {
          projectId: this.id
        },
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.taskList = res.data.list
      })
    },
    getShareFileList () {
      getFileList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.shareFileList = res.data.list
      })
    },
    taskChange (value) {
      if (value) {
        getTaskMember({
          ids: value
        }).then(res => {
          this.taskMember = res.data
        })
      } else {
        this.taskMember = []
      }
    },
    checkFile (rule, value, callback) {
      if (this.form.fileUrl) {
        callback()
      } else {
        callback(new Error('请上传文件！'))
      }
    }
  },
  computed: {

  },
}
</script>

<style scoped lang="scss">
.drag-uploader {
  /deep/ .ant-upload-drag {
    display: none;
  }
}
</style>
