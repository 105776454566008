import request from '@/utils/request'

export function getPositionList(params) {
    return request({
        url: '/projectPosition/page',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 10000,
        },
    })
}

export function savePosition (data) {
    return request({
        url: '/projectPosition/submitProjectPositions',
        method: 'POST',
        data
    })
}

export function getTaskTypeList() {
    return request({
        url: '/taskCategory/page',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 10000,
        },
    })
}

export function saveTaskType (data) {
    return request({
        url: '/taskCategory/submitTaskCategory',
        method: 'POST',
        data
    })
}

export function getTaskPositionList(params) {
    return request({
        url: '/taskPosition/page',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 10000,
        },
    })
}

export function saveTaskPosition (data) {
    return request({
        url: '/taskPosition/submitTaskPosition',
        method: 'POST',
        data
    })
}

export function getCategoryList(params) {
    return request({
        url: '/expenditureCategory/page',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 10000,
        },
    })
}

export function saveCategory (data) {
    return request({
        url: '/expenditureCategory/submitExpenditureCategory',
        method: 'POST',
        data
    })
}








