<template>
  <div class="full-card padding-t50">
    <div class="display-f justify-between">
      <div>售后专员：{{ detail.afterSaleCommissioner }}</div>
      <div>
        <a-button type="primary" @click="linkShow = true" icon="copy" v-hasPermission="['batAfterSaleCare']">批处理</a-button>
        <a-button type="primary" @click="openCreate('create')" icon="plus" class="margin-l20" v-has-permission="['addAfterSaleCare']">新增售后提醒</a-button>
      </div>
    </div>
    <a-card style="margin-top: 15px">
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
      >
        <template slot="maintenanceStatus" slot-scope="text">
          <span v-if="text === 0">待保养</span>
          <span v-if="text === 1">已保养</span>
        </template>
        <template slot="remindedTimes" slot-scope="text, record">
          <div>
            <span :class="record.fontColor === 1 ? 'red' : 'font-c3'">{{ record.timeoutRemindTimes }}</span>/
            <span>{{ text }}</span>
          </div>
        </template>
        <template slot="finishedMaintenanceTimes" slot-scope="text, record">
          <span :class="record.fontColor === 1 ? 'red' : 'font-c3'">{{ record.timeountMaintenanceTimes }}</span>/
          <span>{{ text }}</span>
        </template>
        <template slot="id" slot-scope="text, record">
          <img src="@/assets/image/common/edit.png" alt="" @click="openCreate('edit', record)" v-has-permission="['setMaintenanceDate']">
          <img src="@/assets/image/common/files.png" alt="" class="margin-l20" @click="openLog(record)">
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deleteAfterSaleCare']">
        </template>
      </a-table>
    </a-card>
    <a-drawer
        :title="`${form.id? '编辑' : '新增'}售后提醒`"
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="700px"
        class="drawer"
        v-if="createShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="产品名称" prop="productId">
              <a-select v-model="form.productId" placeholder="请选择关联产品" :disabled="!!(form.id)">
                <a-select-option :value="item.id" v-for="(item, index) in productList" :key="item.id">{{ item.productName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="下次保养日期" prop="nextMaintenanceDate">
              <div v-if="form.notifyStatus === 1">{{ form.nextMaintenanceDate }}</div>
              <a-date-picker
                  :value="form.nextMaintenanceDate ? moment(form.nextMaintenanceDate) : null"
                  @change="dateChange"
                  style="width: 100%"
                  v-else
              />
            </a-form-model-item>
            <a-form-model-item label="是否已提醒客户" prop="notifyStatus" v-if="cacheData.notifyStatus !== 1">
              <a-radio-group name="radioGroup" v-model="form.notifyStatus">
                <a-radio :value="0">待提醒</a-radio>
                <a-radio :value="1">已提醒</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="客户是否已保养" prop="maintenanceStatus"  v-if="cacheData.notifyStatus === 1">
              <a-radio-group name="radioGroup" v-model="form.maintenanceStatus">
                <a-radio :value="0">待保养</a-radio>
                <a-radio :value="1">已保养</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-textarea placeholder="请输入备注" v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button type="default" icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>

    </a-drawer>
    <a-drawer
        title="批处理"
        placement="right"
        :visible="linkShow"
        @close="linkShow = false"
        width="500px"
        class="drawer"
        v-if="linkShow"
    >
      <div class="drawer-cot">
        <div class="drawer-form">
          <a-form-model
              labelAlign="right"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 16 }"
          >
            <a-form-model-item label="下次保养日期">
              <a-date-picker
                  v-model="linkForm.date"
                  @change="linkDateChange"
                  style="width: 100%"/>
            </a-form-model-item>
          </a-form-model>
          <a-table
              :row-selection="rowSelection"
              :columns="paramColumns"
              :data-source="list"
              :loading="tableLoading"
              :pagination="false"
              rowKey="id"
              bordered
              class="margin-t30"
          >
          </a-table>
        </div>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="linkSubmit">提交</a-button>
          <a-button class="margin-l20" icon="close" @click="linkShow = false">取消</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="日志"
        placement="right"
        :visible="logShow"
        @close="logShow = false"
        width="700px"
        class="drawer"
        v-if="logShow"
    >
      <div class="drawer-cot">
        <div class="drawer-form">
          <div class="padding-lr20">
            <div class="font-s16 margin-b30"><span class="font-c9">产品名称：</span>{{ logList.productName }}</div>
            <a-timeline>
              <a-timeline-item v-for="(item, index) in logList.maintenanceLogVOList" :key="item.id">
                <div slot="dot" class="display-f align-items-c">
                  <a-icon type="clock-circle-o" style="font-size: 16px;" />
                  <span class="width-100 display-i line-h25">{{ item.createTime }}</span>
                </div>
                <div class="">
                  <div class="padding-10 border-r3 border">
                    <a-row :gutter="10">
                      <a-col span="4">触发事件</a-col>
                      <a-col span="14">{{ item.triggerEvent }}</a-col>
                    </a-row>
                    <a-row :gutter="10" class="margin-t10">
                      <a-col span="4">操作人</a-col>
                      <a-col span="14">{{ item.operator }}</a-col>
                    </a-row>
                    <a-row :gutter="10" class="margin-t10">
                      <a-col span="4">备注</a-col>
                      <a-col span="14">{{ item.remark }}</a-col>
                    </a-row>
                  </div>
                </div>
              </a-timeline-item>
            </a-timeline>
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { deleteProblem } from '@/api/problem/problem'
import {
  createAfterSale,
  getAfterSaleList,
  getLogList,
  setMaintenanceDate,
  setMaintenanceStatus,
  getProductByProject, deleteAfterSale, getProductAndAccessoriesByProject
} from '../../../api/project/projectDetail'
import { batProcess, getProductList } from '../../../api/product/product'
import moment from 'moment'
import { getProjectDetail } from '../../../api/project/project'
export default {
  name: 'afterSale',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  components: {

  },
  data () {
    return {
      columns: [
        {
          title: '产品名称',
          dataIndex: 'productName'
        },
        {
          title: '上次保养日期',
          dataIndex: 'lastMaintenanceDate'
        },
        {
          title: '下次保养日期',
          dataIndex: 'nextMaintenanceDate'
        },
        {
          title: '保养状态',
          dataIndex: 'maintenanceStatus',
          scopedSlots: { customRender: 'maintenanceStatus' }
        },
        {
          title: '应保养次数',
          dataIndex: 'maintenanceTimes'
        },
        {
          title: '超时提醒次数/已提醒次数',
          dataIndex: 'remindedTimes',
          scopedSlots: { customRender: 'remindedTimes' }
        },
        {
          title: '超时保养次数/已保养次数',
          dataIndex: 'finishedMaintenanceTimes',
          scopedSlots: { customRender: 'finishedMaintenanceTimes' }
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      linkShow: false,
      filterForm: {
        projectId: this.id,
        pageNum: 1,
        pageSize: 10
      },
      form: {
        projectId: this.id
      },
      cacheData: {},
      linkForm: {},
      page: 1,
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      rules: {
        productId: [
          { required: true, message: '请选择产品', trigger: 'change' }
        ]
      },
      productList: [],
      paramSelectedRowKeys: [],
      paramColumns: [
        {
          title: '产品名称',
          dataIndex: 'productName'
        },
        {
          title: '下次保养日期',
          dataIndex: 'nextMaintenanceDate'
        }
      ],
      logShow: false,
      logList: [],
      detail: {}
    }
  },
  mounted () {
    this.getDetail()
    this.getList()
    this.getProduct()
  },
  methods: {
    moment,
    getDetail () {
      getProjectDetail({
        id: this.id
      }).then(res => {
        this.detail = res.data
      })
    },
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getAfterSaleList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) {
        this.form = { ...item }
        this.cacheData = { ...item }
        this.form.afterSaleCareId = item.id
      }
      this.createShow = true
    },
    closeCreate () {
      this.form = {
        projectId: this.id
      }
      this.cacheData = { }
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteAfterSale({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.cacheData.id) {
            if (this.cacheData.notifyStatus === 1) {
              setMaintenanceStatus(this.form).then(() => {
                this.$message.success('操作成功！')
                this.getList()
                this.closeCreate()
              })
            } else {
              setMaintenanceDate(this.form).then(() => {
                this.$message.success('操作成功！')
                this.getList()
                this.closeCreate()
              })
            }
          } else {
            createAfterSale(this.form).then(() => {
              this.$message.success('操作成功！')
              this.getList()
              this.closeCreate()
            })
          }

        }
      })
    },
    dateChange (date, dateString) {
      this.form.nextMaintenanceDate = dateString
      this.$forceUpdate()
    },
    linkDateChange (date, dateString) {
      this.linkForm.date = dateString
    },
    getProduct () {
      getProductAndAccessoriesByProject({
        projectId: this.id
      }).then(res => {
        this.productList = res.data
      })
    },
    linkSubmit () {
      batProcess({
        nextMaintenanceDate: this.linkForm.date,
        afterSaleCareIdList: this.paramSelectedRowKeys
      }).then(() => {
        this.$message.success('已提交！')
        this.linkShow = false
        this.filterForm = {
          projectId: this.id,
              pageNum: 1,
              pageSize: 10
        },
        this.getList()
      })
    },
    openLog (item) {
      getLogList({
        afterSaleCardId: item.id
      }).then(res => {
        this.logList = res.data
        this.logShow = true
      })
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          selectedRows.forEach(item => {
            item.parameterId = item.id
          })
          this.paramSelectedRowKeys = selectedRowKeys
        },
        selectedRowKeys: this.paramSelectedRowKeys
      };
    },
  },
}
</script>

<style scoped lang="scss">
  .drawer {
    /deep/ .ant-timeline-item-head-custom{
      left: 40px;
      top: 40px;
    }
    /deep/ .ant-timeline-item-content{
      margin-left: 100px;
    }
  }
</style>
